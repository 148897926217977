import { BackTop, Layout } from "antd";
import React from "react";
import MyFooter from "./MyFooter";
import MyHeader from "./MyHeader";
import MyMenu from "./MyMenu";
import { Switch, Route, withRouter } from "react-router-dom";
import HomePage from "./HomePage";
import Procedures from "./Components/Procedures";

const { Content, Sider } = Layout;
const MOBILE_BORDER = 768;

class App extends React.Component {
  state = {
    width: 0,
    mobile: false,
  };
  constructor() {
    super();
    window.addEventListener("resize", this.update);
  }

  showSettings(event) {
    event.preventDefault();
  }

  update = () => {
    if (
      (window.innerWidth < MOBILE_BORDER && !this.state.mobile) ||
      (window.innerWidth > MOBILE_BORDER && this.state.mobile)
    ) {
      this.setState({
        width: window.innerWidth,
        mobile: !this.state.mobile,
      });
    }
  };

  componentDidMount() {
    this.update();
  }

  render() {
    const isProcedures = this.props.location.pathname.match(/\/procedures/);

    return (
      <Layout style={{ minHeight: "100vh" }}>
        {this.state.mobile ? (
          <MyMenu mobile={this.state.mobile} isProcedures={isProcedures} />
        ) : (
          <Sider width="250px">
            <MyMenu mobile={this.state.mobile} isProcedures={isProcedures} />
          </Sider>
        )}
        <Layout>
          <MyHeader mobile={this.state.mobile} />
          <BackTop />
          <Content style={{ margin: "0 16px" }}>
            <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
              <Switch>
                <Route path="/procedures">
                  <Procedures />
                </Route>
                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            </div>
          </Content>
          <MyFooter />
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(App);
