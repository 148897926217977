import { Col, Divider, Row } from "antd";
import React from "react";
import Address from "./Address";
import Map from "./Map";

class Contact extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Divider>Kontakt</Divider>
        <Row>
          <Col xs={24} sm={24} md={12} style={{ padding: "0 0 5px 0" }}>
            <Address />
          </Col>
          <Col xs={24} sm={24} md={12} style={{ padding: "0 0 5px 0" }}>
            <Map />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Contact;
