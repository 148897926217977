import { Divider, Card, Row } from "antd";
import React from "react";
import EquipmentModal from "./EquipmentModal";

const filePath = `${window.location.origin}/Procedures`;

class Procedures extends React.Component {
  state = {
    visible: false,
    title: "",
    description: "",
    photos: [{ src: "" }],
  };

  closeModal = () => {
    this.setState((prevState) => ({ ...prevState, visible: false }));
  };

  generateImages = (indexFolder, count) => (
    <Row justify="left" key={indexFolder}>
      {[...Array(count).keys()].map((indexImage) => (
        <Card.Grid
          hoverable
          style={{ width: 200 + 24 * 2, height: 133 + 24 * 2 }}
          key={indexImage}
        >
          <img
            src={`${filePath}/${indexFolder}/${indexImage}_200.jpg`}
            alt={`${filePath}/${indexFolder}/${indexImage}_200.jpg`}
            onClick={() => {
              this.setState({
                visible: true,
                title: "item.title",
                description: "item.description",
                photos: [...Array(4).keys()].map((key) => ({
                  src: `${filePath}/${indexFolder}/${indexImage}_2048.jpg`,
                })),
              });
            }}
          />
        </Card.Grid>
      ))}
    </Row>
  );

  render() {
    return (
      <React.Fragment>
        <Divider>Výkony</Divider>
        <EquipmentModal
          visible={this.state.visible}
          title={this.state.title}
          description={this.state.description}
          photos={this.state.photos}
          {...this.state}
          closeModal={this.closeModal}
        />
        <h1>Malá výplň</h1>
        <p>primární okluzální kaz na zubu 16 v horní čelisti</p>
        {this.generateImages(0, 3)}
        <Divider />
        <p>primární mezizubní kaz na zubu č.4 v horní čelisti</p>
        {this.generateImages(1, 5)}
        <Divider />
        <h1>Malá a střední výplň</h1>
        <p>
          primární mezizubní kaz na zubu 4 v horní čelisti, středně veliká výplň
          na zubu 5 v horní čelisti
        </p>
        {this.generateImages(2, 4)}
        <Divider />
        <h1>Velká výplň</h1>
        <p>
          sekundární kaz pod amalgámovou výplní na zubu číslo 6 v horní čelisti
        </p>
        {this.generateImages(3, 4)}
      </React.Fragment>
    );
  }
}

export default Procedures;
