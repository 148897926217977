import { Card } from "antd";
import React from "react";

class Map extends React.Component {
  state = {};
  render() {
    return (
      <Card
        title="Najdete nás v 1. patře budovy, vstup je přes schodiště na zadní straně budovy."
        bordered={true}
        style={{ width: "100%", height: 550 }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d540.7218726201158!2d15.593181670849829!3d49.38993881216526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d1a0d9b697a17%3A0x23491507a68d795e!2sBrtnick%C3%A1%204267%2F1%2C%20586%2001%20Jihlava!5e0!3m2!1scs!2scz!4v1577911494602!5m2!1scs!2scz" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>`,
          }}
        />
      </Card>
    );
  }
}

export default Map;
