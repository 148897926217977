// import { Button } from "antd";
import Carousel, { Modal, ModalGateway } from "react-images";
import React from "react";

class EquipmentModal extends React.Component {
  state = {
    currentImage: 0,
  };

  render() {
    return (
      // <Modal
      //   visible={this.props.visible}
      //   title={this.props.title}
      //   onCancel={this.props.closeModal}
      //   footer={[
      //     <Button
      //       key="submit"
      //       type="primary"
      //       onClick={() => this.props.closeModal()}
      //     >
      //       Zavřít
      //     </Button>
      //   ]}
      // >
      //   {this.props.description}
      // </Modal>
      <ModalGateway>
        {this.props.visible ? (
          <Modal
            closeOnBackdropClick={true}
            closeOnEsc={true}
            onClose={this.props.closeModal}
            styles={{
              blanket: (base) => ({
                ...base,
                zIndex: 11,
              }),
              positioner: (base) => ({
                ...base,
                zIndex: 12,
              }),
            }}
          >
            <Carousel
              currentIndex={this.state.currentImage}
              views={
                this.props.photos
                  ? this.props.photos.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title ? x.title : this.props.title,
                    }))
                  : [
                      {
                        src: "",
                      },
                    ]
              }
            />
          </Modal>
        ) : null}
      </ModalGateway>
    );
  }
}

export default EquipmentModal;
