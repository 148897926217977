import { Carousel } from "antd";
import React from "react";

class Home extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Carousel autoplay>
          <div>
            <h3>Mikroskopická stomatologie</h3>
          </div>
          <div>
            <h3>Důraz na kvalitu</h3>
          </div>
          <div>
            <h3>Estetická stomatologie</h3>
          </div>
          <div>
            <h3>Klidné ošetření bez bolesti</h3>
          </div>
        </Carousel>
        <br />
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default Home;
