import { Divider, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Ošetření",
    dataIndex: "name",
    key: "name",
    render: (text) =>
      text.startsWith("Fotokompozitní výplň") && !text.includes("krčková") ? (
        <Link to="/procedures">{text}</Link>
      ) : (
        text
      ),
  },
  {
    title: "Cena",
    dataIndex: "price",
    key: "price",
  },
];

const data = [
  {
    key: "1",
    name: "Fotokompozitní výplň malého rozsahu",
    price: "2000,-",
  },
  {
    key: "2",
    name: "Fotokompozitní výplň středního rozsahu",
    price: "2500,-",
  },
  {
    key: "3",
    name: "Fotokompozitní výplň velkého rozsahu",
    price: "3000,-",
  },
  {
    key: "4",
    name: "Fotokompozitní výplň krčková",
    price: "1500,-",
  },
  {
    key: "5",
    name: "Fotokompozitní overlay přímá",
    price: "4000,-",
  },
  {
    key: "6",
    name: "Endodoncie 1kk včetně postendodontického ošetření",
    price: "6000,-",
  },
  {
    key: "7",
    name: "Endodoncie 2kk včetně postendodontického ošetření",
    price: "7000,-",
  },
  {
    key: "8",
    name: "Endodoncie 3kk (dolní moláry a horní sedmé moláry) včetně postendodontického ošetření",
    price: "8500,-",
  },
  {
    key: "9",
    name: "Endodoncie 4kk (horní 6 moláry) včetně postendodontického ošetření",
    price: "10000,-",
  },
  {
    key: "10",
    name: "Postendododontická dostavba bez FRC čepu",
    price: "2500,-",
  },
  {
    key: "11",
    name: "Postendododontická dostavba s FRC čepem",
    price: "2700,-",
  },
  {
    key: "12",
    name: "Konvenční fixace korunky",
    price: "800,-",
  },
  {
    key: "13",
    name: "Reendodoncie 1kk včetně postendodontického ošetření",
    price: "6500,-",
  },
  {
    key: "14",
    name: "Rendodoncie 2kk včetně postendodontického ošetření",
    price: "8500,-",
  },
  {
    key: "15",
    name: "Rendodoncie 3kk (dolní moláry a horní sedmé moláry) včetně postendodontického ošetření",
    price: "10000,-",
  },
  {
    key: "16",
    name: "Rendodoncie 4kk (horní 6 moláry) včetně postendodontického ošetření",
    price: "10400,-",
  },
  {
    key: "17",
    name: "Preprotetická chirurugie - prohloubení vestibula HČ",
    price: "2500,-",
  },
  {
    key: "18",
    name: "Preprotetická chirurugie - prohloubení vestibula DČ",
    price: "3000,-",
  },
  {
    key: "19",
    name: "Resekce kořenového hrotu pod OM",
    price: "4000,-",
  },
  {
    key: "20",
    name: "OTISK jedna čelist",
    price: "1500,-",
  },
];

class PriceList extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Divider>Ceník nejčastějších výkonů</Divider>
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          pagination={false}
          style={{ maxWidth: "550px", margin: "auto" }}
        />
      </React.Fragment>
    );
  }
}

export default PriceList;
