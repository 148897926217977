import { Divider, Card, Row, Col, Modal, Button } from "antd";
import React from "react";
import * as Sphere from "photo-sphere-viewer";
import "photo-sphere-viewer/dist/photo-sphere-viewer.min.css";

const filePath = `${window.location.origin}/SurgeryImages/`;
class SurgeryOld extends React.Component {
  state = {
    width: 0,
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth,
    });
  }

  getModal = (id) => {
    Modal.info({
      icon: null,
      closable: true,
      centered: true,
      width: window.innerWidth * 0.9,
      height: window.innerHeight * 0.8,
      footer: (
        <Button key="submit" type="primary" onClick={this.props.closeModal}>
          Zavřít
        </Button>
      ),
      content: (
        <div
          style={{
            width: "100%",
            height: window.innerHeight * 0.8,
          }}
          id={id}
        ></div>
      ),
    });
  };

  generateSphere = (panorama, id) => {
    Sphere({
      parent: this,
      container: document.getElementById(id),
      panorama,
      time_anim: false,
      navbar: ["fullscreen"],
      navbar_style: {
        backgroundColor: "rgba(250, 250, 250, 251)",
      },
    });
  };
  render() {
    return (
      <React.Fragment>
        <Divider>Zubní ordinace</Divider>

        <Row justify="space-around" align="middle">
          <Col xs={24} sm={24} md={12} justify="space-around" align="middle">
            <Card
              bordered={false}
              hoverable
              style={{ maxWidth: 500, height: 333, margin: "1em" }}
              cover={<img alt="" src={`${filePath}/1preview2.jpg`} />}
              onClick={() => {
                this.getModal("viewer");
                setTimeout(() => {
                  this.generateSphere(
                    `${window.location.origin}/SurgeryImages/1.jpg`,
                    "viewer"
                  );
                }, 500);
              }}
            ></Card>
          </Col>
          <Col xs={24} sm={24} md={12} justify="space-around" align="middle">
            <Card
              bordered={false}
              hoverable
              style={{ maxWidth: 500, height: 333, margin: "1em" }}
              onClick={() => {
                this.getModal("viewer2");
                setTimeout(() => {
                  this.generateSphere(
                    `${window.location.origin}/SurgeryImages/3.jpg`,
                    "viewer2"
                  );
                }, 500);
              }}
            >
              <img
                alt=""
                src={`${filePath}/3preview2.jpg`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SurgeryOld;
