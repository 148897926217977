import React from "react";
import { Divider, Row, Card, Col } from "antd";

class Toothbrushing extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Divider>Správná technika čištění zubů</Divider>
        <Row justify="space-around" align="middle">
          {[
            "https://www.youtube.com/embed/RTI8o-GCuDM",
            "https://www.youtube.com/embed/jaEy6PnyzYs",
            "https://www.youtube.com/embed/bY_t0tVfIEw",
            "https://www.youtube.com/embed/ZKLV_oIr-gE",
          ].map((src) => (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={src === "https://www.youtube.com/embed/xperOPXd_3A" ? 24 : 12}
              justify="space-around"
              align="middle"
              key={src}
            >
              <Card
                hoverable
                style={{ maxWidth: 560, height: 315, margin: "1em" }}
              >
                <div>
                  <iframe
                    title={src}
                    src={src}
                    frameBorder="0"
                    allowFullScreen=""
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }
}

export default Toothbrushing;
