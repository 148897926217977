import { Layout } from "antd";
import React from "react";
import preval from "preval.macro";
const { Footer } = Layout;

class MyFooter extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Footer style={{ textAlign: "center" }}>
          Ant Design ©2019 Created by JZ - Last update:{" "}
          {preval`module.exports = new Date().toLocaleString();`}
        </Footer>
      </React.Fragment>
    );
  }
}

export default MyFooter;
