import { Affix, Menu as AntMenu } from "antd";
import React from "react";
import { scroller } from "react-scroll";
import { slide as Menu } from "react-burger-menu";
import { Link, withRouter } from "react-router-dom";

const mainMenuItems = [
  { key: "news", title: "Aktuality" },
  { key: "toothbrushing", title: "Správná technika čištění zubů" },
  { key: "equipment", title: "Vybavení" },
  { key: "schedule", title: "Ordinační hodiny" },
  { key: "price", title: "Ceník" },
  { key: "team", title: "Tým" },
  // { key: "booking", title: "Online objednávka" },
  { key: "contact", title: "Kontakt" },
  { key: "surgery", title: "Ordinace" },
];
const proceduresMenuItems = [{ key: "back", title: "Zpět" }];

class MyMenu extends React.Component {
  state = {
    menuOpen: false,
  };

  handleStateChange(state) {
    this.setState((prevState) => ({
      ...prevState,
      menuOpen: state.isOpen,
    }));
  }

  render() {
    const isProcedures = this.props.isProcedures;
    const menuItems = isProcedures ? proceduresMenuItems : mainMenuItems;
    return (
      <React.Fragment>
        {this.props.mobile ? (
          <Menu
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}
            disableAutoFocus
          >
            {menuItems.map((menuItem, index) =>
              menuItem.key === "back" ? (
                <li
                  id={menuItem.key + index}
                  key={menuItem.key + index}
                  className="menu-item"
                >
                  <Link to="/">{menuItem.title}</Link>
                </li>
              ) : (
                <li
                  id={menuItem.key + index}
                  key={menuItem.key + index}
                  className="menu-item"
                  onClick={() => {
                    scroller.scrollTo(menuItem.key, {
                      duration: 800,
                      delay: 100,
                      offset: -64,
                      smooth: "easeInOutQuart",
                    });
                    this.setState({
                      menuOpen: false,
                    });
                  }}
                >
                  {menuItem.title}
                </li>
              )
            )}
          </Menu>
        ) : (
          <Affix offsetTop={10}>
            <AntMenu theme="dark" defaultSelectedKeys={["news"]} mode="inline">
              {menuItems.map((menuItem, index) =>
                menuItem.key === "back" ? (
                  <AntMenu.Item key={menuItem.key + index}>
                    <Link to="/">{menuItem.title}</Link>
                  </AntMenu.Item>
                ) : (
                  <AntMenu.Item
                    key={menuItem.key + index}
                    onClick={() => {
                      scroller.scrollTo(menuItem.key, {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                      });
                    }}
                  >
                    {menuItem.title}
                  </AntMenu.Item>
                )
              )}
            </AntMenu>
          </Affix>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(MyMenu);
