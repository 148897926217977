import { Avatar, Divider, List } from "antd";
import React from "react";

const filePath = `${window.location.origin}/Equipment`;

const dataList = [
  {
    avatarName: "iconMikroskop.jpg",
    title: "Stomatologický mikroskop",
    description:
      "téměř všechny výkony provádíme se zvětšením. Šetříme díky tomu zdravé tvrdé zubní tkáně a máme velkou jistotu při prepaci kazivých částí zubu. Mikroskop nám umožňuje přehledně provádět i specializované výkony v kořenových kanálcích, v případě nutnosti endodontického ošetření zubu. Mikroskop používáme i na menší chirurgické výkony.",
    photos: [...Array(2).keys()].map((key) => ({
      src: `${filePath}/Mikroskop/${key}.jpg`,
    })),
  },
  {
    avatarName: "iconLupovky.jpg",
    title: "Lupové brýle",
    description:
      "vyšetření vašeho chrupu a dutiny ústní provádíme se zvětšením.",
    photos: [...Array(3).keys()].map((key) => ({
      src: `${filePath}/Lupovky/${key}.jpg`,
    })),
  },
  {
    avatarName: "iconOPG.jpg",
    title: "Panoramatický RTG snímek",
    description:
      "je důležitou součástí stomatologického vyšetření. Získáme komplexní informace o horní i dolní čelisti, čelistních kloubech a o stavu všech zubů. Odhalí nám skryté zánětlivé procesy, neprořezané zuby a další. Tento snímek můžeme v případě potřeby zhotovit každé 2 roky.",
    photos: [...Array(1).keys()].map((key) => ({
      src: `${filePath}/OPG/${key}.jpg`,
    })),
  },
  {
    avatarName: "iconRTG.jpg",
    title: "Intraorální RTG",
    description:
      "pomůže nám diagnostikovat již i malé zubní kazy, které ještě nejsou viditelné při klinickém vyšetření v ústech. Odhalíme zubní kazy skrývající se pod výplněmi a získáme komplexní informace o jednotlivém zubu jako celku. Dále můžeme hodnotit například stav závěsného aparátu daného zubu ve vztahu k parodontóze.",
    photos: [...Array(4).keys()].map((key) => ({
      src: `${filePath}/RTG/${key}.jpg`,
    })),
  },
  // {
  //   avatarName: "iconKreslo.jpg",
  //   title: "Křeslo",
  //   description: "",
  //   photos: [...Array(4).keys()].map(key => ({
  //     src: `${filePath}/Kreslo/${key}.jpg`
  //   }))
  // }
];

class Equipment extends React.Component {
  state = {
    visible: false,
    title: "",
    description: "",
    photos: [{ src: "" }],
  };

  closeModal = () => {
    this.setState((prevState) => ({ ...prevState, visible: false }));
  };

  render() {
    return (
      <React.Fragment>
        <Divider>Vybavení</Divider>
        {/* <EquipmentModal
          // visible={this.state.visible}
          // title={this.state.title}
          // description={this.state.description}
          // photos={this.state.photos}
          {...this.state}
          closeModal={this.closeModal}
        /> */}
        <List
          itemLayout="horizontal"
          dataSource={dataList}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={filePath + "/" + item.avatarName} />}
                title={
                  // <Button
                  //   type="link"
                  //   style={{ padding: "0" }}
                  // onClick={() => {
                  //   this.setState({
                  //     visible: true,
                  //     title: item.title,
                  //     description: item.description,
                  //     photos: item.photos
                  //   });
                  // }}
                  // >
                  // </Button>

                  item.title
                }
                description={item.description}
              />
            </List.Item>
          )}
        />
      </React.Fragment>
    );
  }
}

export default Equipment;
