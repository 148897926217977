import { Card, Divider, Row, Col } from "antd";
import React from "react";

const filePath = `${window.location.origin}/Team/`;

class Team extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Divider>Tým</Divider>
        <Row>
          <Col xs={24} sm={24} md={12} style={{ padding: "5px" }}>
            <Card
              style={{
                margin: "auto",
                maxWidth: "300px",
              }}
              cover={<img alt="example" src={`${filePath}/doctor.jpg`} />}
            >
              <Card.Meta
                title="MDDr. Zdeněk Klimeš - praktický zubní lékař"
                description="specializace: mikroskopická stomatologie"
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} style={{ padding: "5px" }}>
            <Card
              style={{
                margin: "auto",
                maxWidth: "300px",
              }}
              cover={<img alt="nurse" src={`${filePath}/nurse.jpg`} />}
            >
              <Card.Meta
                title="Bc. Monika Kulíšková"
                description="všeobecná zdravotní sestra"
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Team;
