import { Card } from "antd";
import React from "react";

class Address extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Card
          title="Adresa"
          bordered={true}
          style={{ width: "100%", height: 200, margin: "0" }}
        >
          Stomasun s.r.o.
          <br />
          IČO 077 165 67
          <br />
          Brtnická 4267/1
          <br />
          Jihlava 586 01
          <br />
          odpovědná osoba: MDDr. Zdeněk Klimeš
          <br />
        </Card>
        <Card
          title="Telefon"
          bordered={true}
          style={{ width: "100%", height: 160, margin: "15px 0 0 0" }}
        >
          <b>560 000 372</b>
        </Card>
        <Card
          title="Email"
          bordered={true}
          style={{ width: "100%", height: 160, margin: "15px 0 0 0" }}
        >
          <b>stomatologieklimes@gmail.com</b>
        </Card>
      </React.Fragment>
    );
  }
}

export default Address;
