import { Divider, List, Row } from "antd";
import React from "react";

const dataList = [
  {
    title: "čtvrtek 22. 8. a v pátek 23. 8. 2024 zavřeno",
    description:
      "Ve čtvrtek 22. 8. a v pátek 23. 8. 2024 bude ordinace z důvodu čerpání dovolené uzavřena.",
  },
  {
    title: "Stomatologie – nabídka práce",
    description: (
      <p>
        Do zubní ordinace v Jihlavě přijmu dentální hygienistku na jakýkoliv
        pracovní úvazek.
        <br />
        Nabízím klidné pracovní prostředí, moderní technické vybavení, dobré
        finanční ohodnocení.
        <br />
        Nástup možný ihned.
        <br />
        <br />
        V případě Vašeho zájmu o více informací prosím pište na e-mailovou
        adresu: stomatologieklimes@gmail.com
        <br />
      </p>
    ),
  },
  {
    title: "Stomatologie – nabídka práce",
    description: (
      <p>
        Do zubní ordinace v Jihlavě přijmu všeobecnou sestru (zubní
        instrumentářku/instrumentáře) na plný pracovní úvazek.
        <br />
        Požaduji milé vystupování, komunikativnost, pozitivní přístup k práci a
        schopnost práce s PC.
        <br />
        Nabízím klidné pracovní prostředí, jednosměnný provoz, férové jednání,
        dobré finanční ohodnocení.
        <br />
        Nástup od srpna/září 2023.
        <br />
        <br />
        V případě Vašeho zájmu o více informací prosím pište na e-mailovou
        adresu: stomatologieklimes@gmail.com
        <br />
      </p>
    ),
  },
  // {
  //   title: "Zubní pohotovost 29. 8.",
  //   description:(
  //     <p>
  //       V neděli 29. 8. bude v naší ordinaci od 8.00 do 12.00 pohotovostní služba.
  //       <br />
  //       <br />
  //       Prosíme všechny pacienty, aby vždy nejprve volali telefonní číslo <b>560 000 372</b>. Bude jim sdělen konkrétní čas, ve který se dostaví do čekárny.
  //       <br />
  //       <br />
  //       Jiné osoby nebudou do čekárny vpuštěny, aby nedocházelo k dalším kontaktům.
  //       <br />
  //       Děkujeme za pochopení.
  //       <br />
  //     </p>
  //   ),
  // },
  {
    title: "Dentální Hygiena",
    description: (
      <p>
        Nově přijímáme pacienty na dentální hygienu.
        <br />
        Můžete se objednat na telefonním čísle 560 000 372
        <br />
        <br />
        <Row justify="left">
          <img alt="DENTAL" src="News/dental.png" width="200px" />
        </Row>
      </p>
    ),
  },
  // {
  //   title: "Pozastavujeme vstupní prohlídky a registrace nových pacientů",
  //   description: (
  //     <p>
  //       Z důvodu naplněné kapacity pozastavujeme vstupní prohlídky a registrace
  //       nových pacientů.
  //       <br />
  //       <br />
  //       Děkujeme za pochopení.
  //       <br />
  //       <br />
  //       <Row justify="center">
  //         <img alt="STOP" src="News/stopOnly.png" width="200px" />
  //       </Row>
  //     </p>
  //   ),
  // },
  // {
  //   title: "Upozornění",
  //   description: (
  //     <p>
  //       Vážené dámy a pánové,
  //       <br />
  //       <br />
  //       ordinace stomatologie funguje téměř v běžném provozu.
  //       <br />
  //       <br />
  //       Přesto Vás prosíme dodržujte tyto pravidla:
  //       <br />
  //       <br />
  //       1. V čekárně mějte nasazenou roušku, kterou si sejmete až na vyzvání
  //       lékaře.
  //       <br />
  //       2. Pokud se necítíte dobře zrušte raději telefonicky svoji návštěvu.
  //       <br />
  //       3. V případě jakýchkoliv potíží nás nejprve telefonicky kontaktujte.
  //       <br />
  //       <br />
  //       Děkujeme.
  //     </p>
  //   ),
  // },
  // {
  //   title: "26. 9. 2019",
  //   description: (
  //     <div>
  //       Základem správné péče o chrup je kvalitní dentální hygiena, doporučujeme
  //       tedy pravidelnou návštěvu u dentální hygienistky. Než zprovozníme
  //       ordinaci DH na našem pracovišti, mohou pacienti navštěvovat dentální
  //       hygienistku v ordinaci MUDr. Doležalové.
  //       <br />
  //       <a href="https://www.stomatologie-dolezalova.cz/">
  //         https://www.stomatologie-dolezalova.cz/
  //       </a>
  //     </div>
  //   ),
  // },
];

class News extends React.Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Divider>Aktuality</Divider>
        <List
          itemLayout="horizontal"
          dataSource={dataList}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </React.Fragment>
    );
  }
}

export default News;
